<div class="about-title about-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
               
            <div class="container">
                <div class="about-title-text">
                    <h2>About Us</h2>
                    
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="about-img">
                    <img src="assets/img/kiab1.JPG" alt="about image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-text">   
                    <div class="section-head">
                        <h3>MINDTREE FAB TECHNOLOGIES  an ISO 9001 : 2015  Certified company was established in 2014.                          
"TO ESTABLISH MINDTREE FAB TECHNOLOGIES ,AS A WORLD CLASS STEEL
 FABRICATION UNIT BY UNDERTAKING UNIQUE PROJECTS REQUIRING A HIGH LEVEL OF 
TECHNICAL EXPERTISE."
                        </h3>
                        
                    </div>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            100% Service
                        </li>
                      
                        <li>
                            <i class="icofont-check-circled"></i>
                            Budget Oriented
                        </li>
                        
                    </ul>

                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="process-section process-style-two pt-100 pb-70">
    <div class="container">
        <div class="section-head blue-title text-center">
            <h2>How Do We <span>Work</span></h2>
            <p>Mindtree fab technologies specializes in the custom fabrication of process equipment

                in addition to providing convectional job shop products such as tanks, pressure vessels, traction housing and piping assemblies,
                mindtree fab  provides full service capabilities 
                to furnish packaged systems. Beginning from flow diagrams, P& Ids, or schematics, Mindtree can deliver
                 complete turnkey shop fabricated, Skid mounted modular process systems which meet the client's schedule and budget demands.
                
                Typically, Mindtree Services include project planning, cost estimates, scheduling, layout, drafting, procurement, quality control, fabrication, testing, inspection and shipment; 
                all of which are performed in close compliance with ASME standards</p>
        </div>
        
      
    </div>
</div>

<section class="team-section team-style-two team-style-three pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>CUSTOMERS</span></h2>
           
        </div>

        <div class="row">
            
                <div class="col-lg-4 col-md-6">
                <div class="team-card" style="height: 430px;">
                    <div class="team-img" style="height: 300px;">
                        <img src="assets/img/weg11.jpg" alt="team member">

                        
                    </div>

                    <div class="team-text">
                        <h4>Weg Industries India Private Limited </h4>
                    </div>
                </div>
            </div> 

            <div class="col-lg-4 col-md-6" >
                <div class="team-card"  style="height: 430px;">
                    <div class="team-img"  style="height: 300px;">
                        <img src="assets/img/newastectt.jpeg"  style="margin-top: 100px;" alt="team member">
                    </div>
                    <div class="team-text">
                        <h4>ASTEC Industries </h4>
                    </div>
                </div>
            </div>  
           
          
            <div class="col-lg-4 col-md-6">
                <div class="team-card" style="height: 430px;">
                    <div class="team-img" style="height: 300px;">
                        <img src="assets/img/bfw1.jpg" alt="team member">

                      
                    </div>

                    <div class="team-text">
                        <h4>Bharat Fritz Werner: Bfw</h4>
                    </div>
                </div>
            </div> 

            <div class="col-lg-4 col-md-6" >
                <div class="team-card" style="height: 430px;">
                    <div class="team-img" style="height: 300px;" >
                        <img src="assets/img/cft1.jpg" alt="team member">
                    </div>

                    <div class="team-text">
                        <h4>Craftsman Automation Private Limited</h4>    
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card" style="height: 430px;">
                    <div class="team-img" style="height: 300px;">
                        <img src="assets/img/weir1.jpg" alt="team member">

                       
                    </div>

                    <div class="team-text">
                        <h4>Weir Minerals India Private Limited</h4>
                    </div>
                </div>
            </div>

             
            <div class="col-lg-4 col-md-6" >
                <div class="team-card"  style="height: 430px;">
                    <div class="team-img" style="height: 300px;">
                        <img src="assets/img/vonnew.png" alt="team member">

                        
                    </div>

                    <div class="team-text">
                        <h4>
                            Von Roll Group</h4>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-4 col-md-6" >
                <div class="team-card"  style="height: 430px;">
                    <div class="team-img" style="height: 300px;">
                        <img src="assets/img/proma.jpg"  style="margin-top: 120px;" alt="team member">
                    </div>
                    <div class="team-text">
                        <h4>
                            Proman Crushing Solutions</h4>
                    </div>
                </div>
            </div> 
            <div class="col-lg-4 col-md-6" >
                <div class="team-card"  style="height: 430px;">
                    <div class="team-img" style="height: 300px;">
                        <img src="assets/img/indonew.png" alt="team member">
                    </div>
                    <div class="team-text">
                        <h4>INDO-MIM</h4>
                    </div>
                </div>
            </div> 

            <div class="col-lg-4 col-md-6" >
                <div class="team-card" style="height: 430px;">
                    <div class="team-img" style="height: 300px;">
                        <img src="assets/img/ess1.jpg" alt="team member">

                        
                    </div>

                    <div class="team-text">
                        <h4>
                            Sicoma Mixers India Private Limited</h4>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>