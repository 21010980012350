<div class="header-section">
    <div class="container">   
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-clock-time"></i> Mon - Sat : 09:00AM - 07:30PM</li>
                        <li><i class="icofont-location-pin"></i> Bangalore-560091</li>
                        <li><i class="icofont-phone"></i> <a href="tel:+9060344880 ">+9060344880 ,9663204266</a></li>
                  
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="header-social text-end">
                    <ul>
                        <li><a target="_blank" href="#"><i class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-twitter"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-linkedin"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>
            </div>              
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/micbs1.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/img/micbs1.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        


                                <!--//////////////// homepg1 ///////////////////////////-->
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">HOME</a></li>
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ABOUT</a></li>
                                <li class="nav-item"><a routerLink="/infrastructure" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">INFRASTRUCTURE</a></li>
                                <li class="nav-item"><a routerLink="/projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">PROJECT</a></li>
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">TEAM</a></li>
                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            </ul>

                                

                    <div class="navbar-button">
                        <a routerLink="/contact">Get Quote</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>