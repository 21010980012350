<section class="feature-area pt-100"> 
<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="contnet">
                <div class="feature-tittle">
                    <h2> Heavy Duty Plasma Cutting Machine</h2>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="recent-img">
                <img src="assets/img/heavyplasma.jpeg" alt="project image" alt="recent" />
            </div>
        </div>
    </div>
</div>
 </section> 
<section class="feature-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Plano Milling Machine</h2>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="recent-img">
                    <img src="assets/img/mac2.png" alt="project image" alt="recent" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2> Lathe Machine</h2>
                        <p></p>
                    </div>
                    <!-- <ul>
                        <li>• That's is 6KW Lasser Cutting M/C.</li>
                    </ul> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="recent-img">
                    <img src="assets/img/lathmach.png" alt="project image" alt="recent" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Heavy Duty Radial Drilling Machine</h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="recent-img">
                    <img src="assets/img/drillnewmachine.png" alt="project image" alt="recent" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2> Vertical Turning Machine </h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="recent-img">
                    <img src="assets/img/vpmmachine.png" alt="project image" alt="recent" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2> Horizontal Boring Machine </h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="recent-img">
                    <img src="assets/img/hrmfinal.png" alt="project image" alt="recent" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature-area pt-100"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Laser Cutting Machine</h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="recent-img">
                    <img src="assets/img/lasercutnew.jpeg" alt="project image" alt="recent" />
                </div>
            </div>
        </div>
    </div>
     </section> 
<section class="feature-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
            <div class="contnet">
                <div class="feature-tittle" style="text-align: center;">
                    <h2>Machineries for Fabrication & Finish Supports</h2>
                    
                </div>
                <div style="text-align: center;">
                <ul >
                    <li >• MIG Matic Welding machine, Inverter based-14nos</li><br>
                    <li>• TIG Welding Machine – 1no </li><br>
                    <li>• Radial Drilling Machine (50MM)-1 no</li><br>
                    <li>• Pillar type Drilling Machine(38MM) – 1no</li><br>
                    <li>• Air compressors-2no</li><br>
                </ul>
            </div>
            </div>
        </div>
        </div>
    </div>
</section>