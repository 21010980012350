<div class="team-title team-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="team-title-text">
                    <h2>Our Team Members</h2>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="team-section pt-100 pb-40">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Team Members</span></h2>
            <p>Mindtree fab technologies specializes in the custom fabrication of process equipment.Two Units have been set up at Peenya Industrial Area in South India.
                Employe Strength 160 supported by experienced Engineers,TUV ceritiied  Welders and fitters 
                Cordially, industrial relations. </p>
        </div>
        </div>
        </section>

        <div class="container">
        <div class="why-choose-shape">
            <img src="assets/img/teamnewimage.jpeg" alt="shape" style="aspect-ratio: 2.49; ">
        </div>
    </div>



