<!-- <div class="contact-title contact-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="contact-title-text">
                    <h2>Contact Us</h2>
                 
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="service-section pt-100 pb-70" >
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="service-card" style="height: 290px;">
                    <h3 style="color:black;"><i class="icofont-location-pin"></i>Address </h3>
                    <p >UNIT-1</p>
                    <ul>
                        <li style="color: black;">No 57,
                            15thcross,
                            Byraveshwara Indl Estate,
                            Andrahalli,Bangalore-560091
                        </li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="service-card" style="height: 290px;">
                    <h3 style="color:black;"><i class="icofont-location-pin"></i>Address </h3>
                    <p style="color: black;">UNIT-2</p>
                    <ul>
                        <li style="color: black;">No 83,
                             Byregowda Indl Estate,
                             Heganahhali<br>
                             Bangalore-
                             560091
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="service-card" style="height: 290px;">
                    <h3 style="color:black;"><i class="icofont-location-pin"></i>Address </h3>
                    <p style="color: black;">UNIT-3</p>
                    <ul>
                        <li style="color: black;">No 38 B1,
                            Vijay rolling mill bus stop opp to SBI Bank,
                            Peenya 2nd phase,<br>
                             Bangalore-
                             560058
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="service-card" style="height: 290px;">
                    <h3 style="color:black;"><i class="icofont-phone"></i>Contact us</h3>
                    <ul>
                        <li style="color: black;">Phone : </li>
                        <li style="color: black;">9060344880 ,</li><br>
                        <li style="color: black;">9663204266 </li>
                    </ul>
                    <ul>
                        <li style="color: black;">
                            Email:<br>
                            <span style="overflow: hidden; white-space: wrap; text-overflow: ellipsis; width: 230px; display: inline-block; vertical-align: top; font-size: 0.8rem; font-weight: 500;" title="purchase@mindtreefabtechnologies.com" >
                              purchase@mindtreefabtechnologies.com
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
    </div>
    </div>
</section>
<!-- <section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Choose Aiva</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-friends"></i>
                    </div>
                    <h3>Understanding</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Best Quality</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-chip"></i>
                    </div>
                    <h3>Technology</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-customer-service"></i>
                    </div>
                    <h3>Support 24/7</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-like"></i>
                    </div>
                    <h3>Expert Team</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-coin"></i>
                    </div>
                    <h3>Price Oriented</h3>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="pt-100"></div>