<div class="service-title service-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <!-- <div class="container"> -->
                <div class="service-title service-title-bg" style="color: white;">
                    <h2>Our Recent Projects</h2>
                </div>
            <!-- </div> -->
        </div>
    </div>
</div>

<div class="recent-style-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/ki1.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiz1.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items" >
                    <div class="recent-img">
                        <img src="assets/img/kiaz2.png" alt="project image">
                         <h6 style="font-size: 25px; text-align: center;">Semi-Electric Stacker</h6>
                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz2.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/kiaz3.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz3.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/ki4.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/ki4.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/kiaz5.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz5.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/kiaz6.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz6.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/kiaz7.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz7.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/kiaz8.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz8.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/ki9.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/ki9.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/kiaz10.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz10.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/kiaz12.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz12.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/trstacker.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/trstacker.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/feedchamp.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/feedchamp.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6" >
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/kiaz16.jpg" alt="project image" style="width: 100%; height: 300px;">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/kiaz16.jpg" class="popup-gallery" style="width: 100%;">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items" >
                    <div class="recent-img">
                        <img src="assets/img/motorbody.jpeg" style="width: 100%; height: 300px;" alt="project image">
                         <h6 style="font-size: 25px; text-align: center;">Motor Body</h6>
                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/motorbody.jpeg" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items" >
                    <div class="recent-img">
                        <img src="assets/img/screenimage.jpeg" style="width: 100%; height: 300px;" alt="project image">
                         <h6 style="font-size: 25px; text-align: center;">Screen</h6>
                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/screenimage.jpeg" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items" >
                    <div class="recent-img">
                        <img src="assets/img/bearing-imagenew.jpeg" alt="project image" style="height: 300px;">
                         <h6 style="font-size: 25px; text-align: center;">Bearing Housing</h6>
                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/bearing-imagenew.jpeg" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
