<div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/contact.jpg" alt="contact image">      
                    </div>           
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>We Love to <span> Hear from You</span></h2>
                            <p>Feel free and share with us. We will get you</p>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                                        </div>
                                    </div>
                                
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn page-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/blue.png" alt="logo">
                        </a>
                    </div>
                    <p>Mindtree fab technologies specializes in the custom fabrication of process equipment</p>

                    <div class="email">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                            <button class="default-btn electronics-btn" type="submit"><i class="icofont-location-arrow"></i></button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/about">About Us</a></li>
                        <!-- <li><i class="icofont-simple-right"></i> <a routerLink="/blog">Blog</a></li> -->
                        <li><i class="icofont-simple-right"></i> <a routerLink="/team">Team</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/projects">Project </a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Find Us</h3>
                    <p class="find-text">MINDTREE FAB TECHNOLOGIES </p>

                    <ul class="find-us">
                        <li>                                   
                            <i class="icofont-location-pin"></i> Bangalore-560091  
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel: 9060344880,9663204266"> 9060344880,9663204266 </a>
                        </li>
                        <li>
                            <i class="icofont-ui-message"></i>
                            <a href="mailto: purchase@mindtreefabtechnologies.com">purchase@mindtreefabtechnologies.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>             
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-text text-end">
                        <p>© 2022 MindTree, All Rights Reserved by <a href="#" target="_blank">MindTree</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>