<div class="home-section">
    <div class="home-slider-area owl-carousel owl-theme">
        <div class="home-slider-item items-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1 style="linear-gradient(90deg, rgb(80, 205, 209) 23%, rgba(21,208,154,1) 64%)">MINDTREE FAB TECHNOLOGIES </h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>We provide High Quality Service</h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Our Working Process is Unique</h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Viscous Provided <span>Services</span></h2>
            <h3>We manufacture Material Handling Equipment, Heavy sheet Metal Fabrication and Precision components.</h3>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <h3>Machine Tool Industry</h3>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card active-service">
                    <i class="flaticon-award"></i>
                    <h3>Sectors</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <h3>Material Handling</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <h3>Construction Equipment</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <h3>Mining Equipment</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <h3>Agricultural Equipment</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="why-choose-section why-choose-bg">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 why-choose-img"></div>

            <div class="col-lg-5 offset-lg-6 offset-md-0">
                <div class="why-choose-text">
                    <div class="section-head">
                        <h2>Why Choose Us</h2>
                        <p>Mindtree technologies  is manufacturers and suppliers of a superior range of Precision Sheet Metal Products,Heavy fabrication components,machining
                             components ,
                            CNC Cabinet, machine Enclosures,Apart from this, we also provide  Fabrication Works and Complete Fabrication Solution.
                             </p>
                    </div>
                </div>
                <div class="why-choose-accordian">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <a href="#" class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        INTERNATIONAL QUALITY STANDARD
                                    </a>
                                </h2>
                            </div>                           
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        TIMELY DELIVERY
                                    </a>
                                </h2>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        INDUSTRY LEADING PRICE
                                    </a>
                                </h2>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        CLIENT CENTRIC APPROACH
                                    </a>
                                </h2>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        CUSTOMIZED SOLUTION
                                    </a>
                                </h2>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        ETHICAL BUSINESS PRACTICES
                                    </a>
                                </h2>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        COMPETENT TEAM
                                    </a>
                                </h2>
                            </div>
                        </div>
                    </div> 
                    <div class="why-choose-contact">
                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Your Number">
                            </div>
                            <button type="submit" class="btn btn-primary">Request A Call</button>
                        </form>
                        <p>Get a free call for service</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br>
<div class="counter-section pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="offer-text">
                    <h2>Get <span>Discount</span> on your first Service</h2>

                </div>
            </div>

            <div class="col-lg-7">
                <div class="counter-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">3</span>K</h2>
                                <p>Job Done</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">125+</span></h2>
                                <p>Happy Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">120</span></h2>
                                <p>Experts</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">10+</span></h2>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Team Members</span></h2>
            <p>Our team members work with a wide range of customers and suppliers, 
                so we're constantly learning from each new experience. We don't 
                just know what products our customers need; we know their business
                 and how to
                 best help them succeed in this ever-changing market place.</p>
        </div>
    </div>
</section>

<div class="testimonial-section testimonial-bg ptb-100">
    <div class="testimonial-area">
        <div class="container">
            <div class="testimonial-slider owl-carousel owl-theme text-center">
                <div class="sin-testiText">
                    <h2> Our Client  Says</h2>
           
                    <p>Mindtree has always been a great partner for us. We have been working with Mindtree for more than 3 years now, and we have 
                        found that they have a very professional team and have always delivered quality services on time. Moreover, 
                        their staff is always approachable and helpful, which makes it easy to solve any issue we may face.</p>
                </div>
            </div>
        </div>
    </div>
</div>